import React from 'react';
import './styles.css';

export default function RestScreen() {
  return (
    <iframe
      src="https://api.santolabs.com.br/api/dasboard/clube-meire"
      title="Rest Screen"
      className="iframe"
      allowFullScreen
      sandbox="allow-scripts allow-same-origin"
    />
  );
}
