import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './pages/Home'
import Fipan from './pages/Fipan'

const Routes = () => {

	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/fipan" component={Fipan} />
		</Switch>
	)
}

export default Routes
