import axios from "axios";

const api = axios.create({
  baseURL: 'https://api.santolabs.com.br/api/dasboard/'
 })
// const api = axios.create({
//  baseURL: 'http://localhost:3001/api/dasboard/'
// })
// api.interceptors.request.use((config) => {
//   let token = localStorage.getItem('@APP:token')
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`
//   }
//   return config
// }, (err) => {
//   return Promise.reject(err)
// })

// const apiZanthus = axios.create({
//   baseURL: 'http://192.168.0.210:8877/serv/TC/6/'
// });

export { api }
// https://thingproxy.freeboard.io/fetch/
// 'https://cors-anywhere.herokuapp.com/https://lojasantoantonio.vtexcommercestable.com.br/'
